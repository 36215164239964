import React from "react";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";
import logo from "../assets/image/Sprinklr_Logo_Bouncing_Grey_Small_v6.gif"

const Contact = () => { 
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-hide",
          headerFluid: false,
          footerStyle: "none",
        }}>
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=mattseddon'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=mattseddon'}></script>         
          <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
        </Helmet>  
        {/* <Hero className="position-relative pt-15 pt-md-31 pt-lg-25 pb-14 pb-md-19 pb-lg-33 position-relative overflow-hidden" />  
        <Wave color="#F7F9FC" />          */}
        <div style={{height:"100vh"}} className="d-flex pt-10 pt-md-0 pt-lg-10 pb-8 pb-md-12 pb-lg-23">
          <div className="container">
            {/* Contact Form */}
            <img className="mb-10" src={logo} alt="Springlr" />
            <div id="digma-embed" className="digma" data-projectid="93FEBFCA-BB82-43C5-8787-37BE2A60B0B1"></div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
